import React from 'react'

import { List, ListItem, NumberInput, Paragraph } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'
import { NumberInputExample } from '../../examples/react/NumberInputExample'
import { NumberInputExample2 } from '../../examples/react/NumberInputExample2'
import { NumberInputErrorExample } from '../../examples/react/NumberInputErrorExample'
import { NumberInputDisabledExample } from '../../examples/react/NumberInputDisabledExample'
import { Playground } from '../../components/Playground'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="NumberInput"
    components={[{ component: NumberInput }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Paragraph>
        <Code>NumberInput</Code> on tarkoitettu pienehköjen lukumäärien
        ilmoittamiseen tai tilanteissa, jossa arvo saa olla tietyllä välillä.
        Harkitse, onko parempi käyttää <Code>NumberInput</Code>in sijaan
        tavallista <Code>Input</Code>ia, jonka syöte validoidaan numeroksi.
        Esimerkiksi iän syöttämiseen <Code>NumberInput</Code> ei välttämättä ole
        paras vaihtoehto, ja joissakin tapauksissa <Code>Select</Code>in valmiit
        vastausvaihtoehdot voivat olla toimivampi ratkaisu.
      </Paragraph>
      <Playground
        example={NumberInputExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Virheviesti">
      <Playground
        example={NumberInputErrorExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Disabloitu">
      <Playground
        example={NumberInputDisabledExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Jos <Code>step</Code>-proppia käytetään, tulee sublabelissa kertoa
        sanallisesti, millä tarkkuudella vastaus tulee antaa. Esim.
        &quot;Ilmoita lukumäärä kymmenien tarkkuudella&quot;. Vastaavasti
        minimi- ja maksimiarvoista tulee kertoa sanallisesti.
      </Paragraph>
      <Playground
        example={NumberInputExample2}
        WrapperComponent={FormLayoutWrapper}
      />
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Varmista, että sublabelissa annetaan tarvittava ohjeistus, kuten millä
          tarkkuudella vastaus annetaan tai mitkä ovat minimi- ja maksimiarvo.
        </ListItem>
        <ListItem>
          Varmista, että syötteen pakollisuus on kerrottu ja tulee ilmi myös
          syötekentässä.
        </ListItem>
        <ListItem>
          Älä käytä placeholderia <Code>NumberInput</Code>in yhteydessä, koska
          avustavaa teknologiaa käyttävä saattaa sekoittaa sen varsinaiseen
          syötteeseen.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
