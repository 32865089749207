import React from 'react'

import { Col, FormGroup, NumberInput, Row } from '@te-digi/styleguide'

const NumberInputDisabledExample = () => (
  <FormGroup noMargin>
    <Row>
      <Col lg={6}>
        <NumberInput
          disabled
          label="Valitse määrä"
          max={100}
          placeholder="kpl"
        />
      </Col>
    </Row>
  </FormGroup>
)

export { NumberInputDisabledExample }
