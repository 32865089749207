import React, { ComponentProps, useState } from 'react'

import { Col, FormGroup, FormText, NumberInput, Row } from '@te-digi/styleguide'

import { Code } from '../../components/Code'

const NumberInputExample2 = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof NumberInput>['value']>(null)

  return (
    <FormGroup noMargin>
      <Row>
        <Col lg={8}>
          <NumberInput
            help="Help"
            label="Valitse määrä"
            onChange={e => setValue(e)}
            placeholder="kpl"
            subLabel="Ilmoita määrä kymmenien tarkkuudella, maksimissaan 100."
            required
            max={100}
            step={10}
            value={value}
          />
          <FormText>
            Arvo: <Code>{JSON.stringify(value)}</Code>
          </FormText>
        </Col>
      </Row>
    </FormGroup>
  )
}

export { NumberInputExample2 }
