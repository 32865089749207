import React, { ComponentProps, useState } from 'react'

import { Col, FormGroup, FormText, NumberInput, Row } from '@te-digi/styleguide'

import { Code } from '../../components/Code'

const NumberInputExample = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof NumberInput>['value']>(null)

  return (
    <FormGroup noMargin>
      <Row>
        <Col lg={6}>
          <NumberInput
            label="Valitse määrä"
            onChange={e => setValue(e)}
            placeholder="kpl"
            subLabel="Sub Label"
            required
            value={value}
          />
          <FormText>
            Arvo: <Code>{JSON.stringify(value)}</Code>
          </FormText>
        </Col>
      </Row>
    </FormGroup>
  )
}

export { NumberInputExample }
