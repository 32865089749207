import React, { ComponentProps, useState } from 'react'

import { Col, FormGroup, NumberInput, Row } from '@te-digi/styleguide'

const NumberInputErrorExample = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof NumberInput>['value']>(null)

  return (
    <FormGroup noMargin>
      <Row>
        <Col lg={6}>
          <NumberInput
            error="Error"
            label="Valitse määrä"
            max={100}
            onChange={e => setValue(e)}
            placeholder="kpl"
            value={value}
          />
        </Col>
      </Row>
    </FormGroup>
  )
}

export { NumberInputErrorExample }
